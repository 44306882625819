<template>
  <div class="tui tuim ui card donation">
    <div class="tui tuim ui header">
      <h2>Dados da doação</h2>
      <button
        v-show="!edit"
        class="tui tuim ui basic button"
        @click.prevent="edit = !edit">
        Editar <t-icon :icon="[ 'fa', 'pen' ]" class="tui tuim icon edit-form" />
      </button>
    </div>
    <form class="tui tuim ui form card-form">

      <div class="content spacer">
        <div class="fields">
          <div class="sixteen wide field">

            <div class="field">
              <label for="donationFrequency" class="label">Recorrência</label>
              <select
                v-model="donation.frequency"
                :disabled="!edit"
                :class="['ui fluid dropdown', { 'disabled' : !edit }]">
                <option
                  v-for="(item, key) in frequencyOptions" :key="key"
                  :value="item.value"
                  v-text="item.label"
                />
              </select>
            </div>

            <div class="field two-fields">
              <label for="donationValue" class="label">Valor</label>
              <div class="fields">
                <div class="ui labeled input wide field">
                  <div :class="['ui label', { 'disabled' : !edit } ]">BRL.</div>
                  <money
                    name="donation[value]"
                    placeholder="Valor"
                    v-model="amount"
                    :disabled="!edit"
                    :class="{ 'disabled' : !edit }"
                    v-bind="money" />
                </div>
              </div>
            </div>

            <!-- disabled component -->
            <div v-if="false" class="two fields">
              <div class="field">
                <label for="donationDueMonth" class="label">Vencimento</label>
                <input type="text" id="donationDueMonth" name="donation[dueMonth]" class="ui input" v-model="donationDueMonth" />
              </div>

              <div class="field">
                <label for="donationDueYear" class="label">Ano</label>
                <input type="text" id="donationDueYear" name="address[dueYear]" class="ui input" v-model="donationDueYear" />
              </div>
            </div>

            <div :class="['field', { 'error' : errorDay(donation.paymentDay) }]">
              <label for="donationDueDay" class="label">Dia</label>
              <input
                type="number"
                id="donationDueDay"
                name="donation[dueDay]"
                placeholder="Recorrência"
                v-model="donation.paymentDay"
                :disabled="!edit"
                :class="['donation-day', { 'disabled' : !edit }]"/>
            </div>

            <div v-if="edit" class="actions">
              <div :class="['ui cancel button', { 'disabled': validateForm() } ]" @click.prevent="cancel()">
                Cancelar
                <t-icon :icon="[ 'fa', 'times' ]" class="tui tuim icon" />
              </div>
              <div :class="['ui approve primary button', { 'disabled': validateForm() }]" @click.prevent="save()">
                Salvar
                <t-icon :icon="[ 'fa', 'check' ]" class="tui tuim icon" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { currencyFormat } from '@/helpers'
import { updateDonation } from '@/queries/donations/donations.mutations'

export default {
  name: 'TCardDonationDetail',

  data() {
    return {
      edit: false,
      hasError: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        precision: 2,
        masked: true
      },
      frequencyOptions: [
        { value: 'once', label: 'Única' },
        { value: 'monthly', label: 'Mensal' }
      ],
      donationAmount: ''
    }
  },

  computed: {
    ...mapState({
      donation: ({ donations }) => donations.donationDetail
    }),
    amount: {
      set(value) {
        this.donationAmount = value.replace(',', '').replace('.', '')
      },
      get() {
        return currencyFormat(this.donation.amount)
      }
    },
    donationDueMonth () {
      return ''
    },
    donationDueYear () {
      return ''
    }
  },

  methods: {
    ...mapActions('notifications', [ 'toggleNotification' ]),

    bindPlugins() {
      $('.ui.fluid.dropdown').dropdown()
    },

    validateDay(value) {
      if (!value) return
      const reg = /^([1-9]|[12][0-9]|3[01])$/g  // eslint-disable-line
      return !value.toString().match(reg)
    },

    errorDay(day) {
      if (this.validateDay(day)) {
        this.hasError = true
        return true
      } else {
        this.hasError = false
        return false
      }
    },

    validateForm() {
      const { amount, frequency, paymentDay } = this.donation

      if (!amount || !frequency || !paymentDay || this.hasError) {
        return true
      }

      return false
    },

    save() {
      if (!this.hasError) {

        this.donation.amount = this.donationAmount

        const { id, amount, frequency, paymentDay, paymentMethod, customer, card, bankAccount, address } = this.donation

        const saveObject = {
          id: id,
          subscription: {
            amount: Number(amount),
            frequency,
            paymentDay: Number(paymentDay),
            paymentMethod,
            customerId: customer.id
          }
        }

        if (card) {
          saveObject.subscription.cardId = card.id
        } else if (bankAccount) {
          saveObject.subscription.bankAccountId = bankAccount.id
        }

        if (address) {
          saveObject.subscription.addressId = address.id
        }

        updateDonation(saveObject.id, saveObject.subscription).then(res => {
          const { errors } = res.data.updateSubscription
          let type = ''
          if (!errors) {
            type = 'success'
          } else {
            type = 'error'
          }
          this.toggleNotification({ active: true, type })
          this.edit = false
        }).catch(e => {
          Vue.$log.error('error', e)
          this.toggleNotification({ active: true, type: 'error' })
        })
      }
    },

    cancel() {
      this.edit = false
    }
  },

  mounted() {
    this.bindPlugins()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/colors.scss";

.ui.labeled.input.field > .ui.label {
  font-size: 16px;
  line-height: 20px;
  transition: all 500ms ease-in;

  &.disabled {
    background-color: white;
    padding-left: 0;
    color: $gray-600;
  }
}

.card.donation .ui.header {
  display: flex;
  justify-content: space-between;
}

.tui.tuim.ui.basic.button .icon.edit-form {
  margin-left: 5px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  border: none;
  background: $white;
  margin-top: 60px;

  .ui.button {
    font-size: 16px;
    line-height: 23px;
    padding: 9px 15px;
    transition: all 200ms ease-in;

    .tui.tuim.icon {
      margin-left: 5px;
    }

    &.cancel {
      border: none;
      color: $gray-500;
      font-weight: 700;
      background: $white;

      &:hover {
        background: $gray-100;
      }
    }
  }
}

.donation-day {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}
</style>
